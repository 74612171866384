'use client'
import React from 'react'
import classes from './Container.module.css'
import {
  Box,
  BoxProps,
  MantineSize,
  Container as MantineContainer,
  ContainerProps,
} from '@mantine/core'
import clsx from 'clsx'
import { Slot } from '@radix-ui/react-slot'

interface Container extends BoxProps, ContainerProps {
  children?: React.ReactNode
  size?: 'app' | MantineSize | undefined
  asChild?: boolean
}

export const Container: React.FC<Container> = ({
  children,
  className,
  size = 'app',
  asChild = false,
  ...rest
}) => {
  return size === 'app' ? (
    <Box component={asChild ? Slot : undefined} className={clsx(classes.root, className)} {...rest}>
      {children}
    </Box>
  ) : (
    <MantineContainer component={asChild ? Slot : undefined} size={size} {...rest}>
      {children}
    </MantineContainer>
  )
}
